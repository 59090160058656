import { AddIcon } from "@chakra-ui/icons"
import { Box, IconButton } from "@chakra-ui/react"
import Head from "next/head"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import CustomTooltip from "./Buttons/CustomTooltip"
import GroupList from "./Groups/GroupList"
import PageHeader from "./Layout/PageHeader"
import NewsList from "./News/NewsList"

const ConnectedHome = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Head>
        <title>{t("seo.home")}</title>
      </Head>
      <NewsList />
      <PageHeader
        fontWeight="normal"
        title={t("group.title")}
        extraActions={
          <CustomTooltip
            label={t("group.create_button")}
            placement="left-start"
          >
            <Link href="/groups/create">
              <IconButton
                variant="brand"
                borderRadius="50%"
                icon={<AddIcon boxSize="10px" />}
                aria-label="groups"
              />
            </Link>
          </CustomTooltip>
        }
      />
      <GroupList />
    </Box>
  )
}

export default ConnectedHome
