import client from "@/api/client"
import {
  Button,
  Center,
  CircularProgress,
  Container,
  SimpleGrid,
} from "@chakra-ui/react"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import NoListContent from "../NoListContent"
import GroupListItem from "./GroupListItem"
import { useInfiniteQuery } from "react-query"
import { Fragment } from "react"
import GroupListSkeleton from "./Skeletons/GroupListSkeleton"

const GroupList = () => {
  const { t } = useTranslation()

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    "get-groups",
    ({ pageParam = 0 }) =>
      client.groups
        .getGroups({
          "pagination[page]": pageParam,
          "pagination[pageSize]": 6,
          sort: "updatedAt:desc",
          //@ts-ignore
          "populate[reviews]": "*",
          "populate[memberships][populate][users_permissions_user][populate]":
            "picture",
        })
        .then((response) => response.data),
    {
      getNextPageParam: (data) => {
        const pageCount = data.meta?.pagination?.pageCount
        const currentPage = data.meta?.pagination?.page

        if (pageCount && currentPage && pageCount > currentPage) {
          return currentPage + 1
        }
      },
    }
  )

  return (
    <Container maxWidth="container.xl">
      {!data && isLoading && <GroupListSkeleton />}
      {!!data && (
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={4} paddingY={4}>
          {!!data &&
            data?.pages?.map((page, index) => (
              <Fragment key={index}>
                {page?.data?.map((group) => (
                  <GroupListItem key={group?.id} group={group} />
                ))}
              </Fragment>
            ))}
        </SimpleGrid>
      )}
      {!!data && isLoading && (
        <Center>
          <CircularProgress color="black" isIndeterminate />
        </Center>
      )}
      {hasNextPage && (
        <Center>
          <Button
            onClick={() => {
              fetchNextPage()
            }}
            variant="brand"
          >
            {t("global.load_more")}
          </Button>
        </Center>
      )}
      {Boolean(data?.pages?.[0].meta?.pagination?.total === 0) && (
        <NoListContent info={t("group.list.no_groups")}>
          <Link href="/groups/create">
            <Button variant="brand">{t("global.create")}</Button>
          </Link>
        </NoListContent>
      )}
    </Container>
  )
}

export default GroupList
