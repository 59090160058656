import client from "@/api/client"
import { Box, SimpleGrid } from "@chakra-ui/react"
import { useTranslation } from "next-i18next"
import { useQuery } from "react-query"
import GroupSectionTitle from "../Groups/GroupSectionTitle"
import NewsListItem from "./NewsListItem"
import NewsListCardSkeleton from "./Skeletons/NewsListCardSkeleton"

const NewsList = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery("get-articles", () =>
    client.articles.getArticles({
      //@ts-ignore
      query: {
        populate: "*",
      },
      "populate[cover]": "*",
      "pagination[page]": 0,
      "pagination[pageSize]": 6,
      sort: "publishedAt:desc",
    })
  )

  const news = data?.data?.data || []

  return (
    <>
      {news.length > 0 && (
        <Box fontFamily="GTMaru" fontWeight="bold" fontSize="xl">
          <GroupSectionTitle>{t("group.news.my_news.title")}</GroupSectionTitle>
          {isLoading && !news && <NewsListCardSkeleton />}

          {!isLoading && !!news?.length && (
            <SimpleGrid columns={{ base: 1, md: 3 }}>
              {news?.map((item, index) => (
                <NewsListItem
                  key={index}
                  title={item?.attributes?.title}
                  url={item?.attributes?.cover?.data?.attributes?.url}
                  id={item?.id}
                />
              ))}
            </SimpleGrid>
          )}
        </Box>
      )}
    </>
  )
}
export default NewsList
