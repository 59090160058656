import { GroupListResponseDataItem } from "@/typings/api"
import {
  AvatarGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import CustomTooltip from "../Buttons/CustomTooltip"
import UserAvar, { getUserName } from "../UserAvatar/UserAvatar"

const GroupMembers = ({
  group,
  showNames,
  hideModal,
}: {
  group: GroupListResponseDataItem["attributes"]
  showNames?: boolean
  hideModal?: boolean
}) => {
  const members = group?.memberships?.data
  const modal = useDisclosure()

  return (
    <>
      <CustomTooltip
        label={
          showNames
            ? members
                ?.map((member) =>
                  getUserName(
                    member?.attributes?.users_permissions_user?.data?.attributes
                  )
                )
                .join(" - ")
            : undefined
        }
      >
        <AvatarGroup
          cursor="pointer"
          onClick={modal.onToggle}
          size="md"
          max={2}
          paddingTop={1}
        >
          {members?.map((member) => {
            const info =
              member?.attributes?.users_permissions_user?.data?.attributes
            const memberInfo = {
              ...info,
              //@ts-expect-error
              picture: info?.picture?.data?.attributes,
            }
            return (
              <UserAvar
                user={memberInfo}
                key={member?.id}
                fontFamily="GTMaru"
              />
            )
          })}
        </AvatarGroup>
      </CustomTooltip>

      <Modal {...modal} size="xs" isOpen={hideModal ? false : modal.isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Membres</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="20rem" mb={4} overflowY="auto">
            <VStack>
              {members?.map((member) => {
                const info =
                  member?.attributes?.users_permissions_user?.data?.attributes
                const memberInfo = {
                  ...info,
                  //@ts-expect-error
                  picture: info?.picture?.data?.attributes,
                }

                return (
                  <Flex
                    key={member.id}
                    gap={2}
                    alignItems="center"
                    width="100%"
                  >
                    <UserAvar
                      showName
                      user={memberInfo}
                      key={member?.id}
                      fontFamily="GTMaru"
                    />
                  </Flex>
                )
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default GroupMembers
