import { getImageUrl } from "@/utils/images"
import { Avatar, Box, HStack, Text } from "@chakra-ui/react"
import Link from "next/link"

const NewsListItem = ({
  id,
  url,
  title,
}: {
  id?: number
  url?: string
  title?: string
}) => {
  return (
    <Box p={4}>
      <Link href={`/news/${id}`}>
        <HStack bg="white" boxShadow="lg" p={2} height="100%">
          <Avatar src={getImageUrl(url)} />
          <Text
            fontSize="sm"
            textAlign="left"
            wordBreak="break-word"
            noOfLines={4}
          >
            {title}
          </Text>
        </HStack>
      </Link>
    </Box>
  )
}

export default NewsListItem
