import ArrowBack from "@/svg/arrowBack"
import {
  Box,
  HStack,
  IconButton,
  Stack,
  Text,
  TextProps,
} from "@chakra-ui/react"
import Head from "next/head"
import { useRouter } from "next/router"
import { ReactNode } from "react"
import { useTranslation } from "next-i18next"
import CustomTooltip from "../Buttons/CustomTooltip"

const PageHeader = ({
  title,
  extraActions,
  descriptionGroup,
  ...props
}: {
  title: string
  extraActions?: ReactNode
  descriptionGroup?: string
} & TextProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const text = (title: string) => (
    <Text
      fontFamily="GTMaru"
      fontWeight="bold"
      fontSize="xl"
      wordBreak="break-word"
      {...props}
    >
      {title}
    </Text>
  )
  return (
    <Box paddingBottom={4}>
      <Head>
        <title>{title}</title>
      </Head>
      <Stack
        flexDirection={{
          base: "column",
          sm: "column",
          md: "row",
          lg: "row",
        }}
        justifyContent="space-between"
        borderBottom="2px solid black"
        paddingBottom={2}
      >
        <HStack>
          {router.pathname !== "/" && (
            <IconButton
              borderRadius="50%"
              variant="ghost"
              aria-label={t("general.back")}
              icon={<ArrowBack height="20px" />}
              onClick={() => router.back()}
            />
          )}
          {descriptionGroup ? (
            <CustomTooltip label={descriptionGroup} placement="right-start">
              {text(title)}
            </CustomTooltip>
          ) : (
            text(title)
          )}
        </HStack>
        {extraActions}
      </Stack>
    </Box>
  )
}

export default PageHeader
