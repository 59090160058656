import client from "@/api/client"
import useAppToast from "@/hooks/useAppToast"
import { GroupListResponseDataItem, MembershipRequest } from "@/typings/api"
import {
    Box,
    Center, HStack,
    Modal,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure
} from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import { PropsWithChildren } from "react"
import { useTranslation } from "next-i18next"
import { useMutation, useQueryClient } from "react-query"



const JoinGroupModal = ({ children, group }: PropsWithChildren & { group: GroupListResponseDataItem }) => {
    const { t } = useTranslation()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const toast = useAppToast()
    const { data: userData } = useSession()
    const router = useRouter()
    const queryClient = useQueryClient()

    const { mutate: handleSubmit } = useMutation(
        "post-recommendations",
        () =>
            client.memberships.postMemberships({
                data: {
                    membership_role: "user",
                    group: group?.id,
                    users_permissions_user: userData?.userId
                }
            } as MembershipRequest),
        {
            onSuccess: () => {
                toast({
                    title: t("join_group.success"),
                    status: "success",
                })
                queryClient.invalidateQueries("get-groups")
                router.push(`/groups/${group?.id}`)
                onClose()
            },
            onError: () => {
                toast({
                    title: t("global.defaultError"),
                    status: "error",
                })
            },
        }
    )



    return (
        <>
            <Box onClick={onOpen}>
                {children}
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <Stack
                        spacing={3}
                        height="min-content"
                        bg="primaryColor"
                        p={4}
                        paddingY={10}
                    >
                        <Box>
                            <Text fontSize="lg" textAlign="center" fontFamily="GTMaru">
                                {t("join_group.title")}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" textAlign="center" fontFamily="GTMaru">
                                {t("join_group.description")}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="xs" textAlign="center" fontFamily="GTMaru">
                                {t("join_group.nota_bene")}
                            </Text>
                        </Box>
                        <HStack alignItems="normal">
                            <Center
                                cursor="pointer"
                                backgroundColor="black"
                                color="white"
                                fontFamily="GTMaru"
                                fontSize="sm"
                                p={1}
                                onClick={onClose}
                                width="100%"
                                textAlign="center"
                            >
                                {t("global.cancel")}
                            </Center>
                            <Center
                                cursor="pointer"
                                backgroundColor="black"
                                color="white"
                                fontFamily="GTMaru"
                                fontSize="sm"
                                p={1}
                                width="100%"
                                textAlign="center"
                                onClick={() => handleSubmit()}
                            >
                                {t("join_group.validate", { name: group?.attributes?.name })}
                            </Center>
                        </HStack>
                    </Stack>
                </ModalContent>
            </Modal>
        </>
    )
}

export default JoinGroupModal
