import { Flex, FlexProps, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import NotificationBadge from "../Layout/NotificationBadge"

const GroupSectionTitle = ({
  children,
  notificationCount,
  ...props
}: {
  children: ReactNode
  notificationCount?: number
} & FlexProps) => {
  return (
    <Flex
      gap={2}
      pb={3}
      borderBottom="2px"
      fontWeight="normal"
      mb={4}
      {...props}
    >
      <Text>{children}</Text>
      {notificationCount ? (
        <NotificationBadge count={notificationCount} />
      ) : null}
    </Flex>
  )
}

export default GroupSectionTitle
