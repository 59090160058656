import { PagePhotoComponent } from "@/typings/api"
import {
  Image,
  Text,
  HStack,
  useMediaQuery,
  Container,
  Box,
} from "@chakra-ui/react"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/pagination"
import { desktopThreshold } from "@/utils/theme"
import { getImageUrl } from "@/utils/images"
import SwipperButton from "./SwipperButton"

type Props = {
  data: PagePhotoComponent[] | undefined
}

const Carousel = ({ data }: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)

  return (
    <Container maxWidth="container.xl" paddingX={{ base: 0, md: 4 }}>
      <HStack>
        <SwipperButton action="prev" isPublicHome hide={!isDesktop} />
        <Swiper
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          modules={[Navigation]}
        >
          {data?.map((item, index) => {
            const img = getImageUrl(item.image?.data?.attributes?.url)
            const alt = item.image?.data?.attributes?.alternativeText

            return (
              <SwiperSlide key={index}>
                <Box height={{ base: "30vh", md: "50vh", lg: "80vh" }}>
                  <Box height="80%" display="flex" alignItems="end">
                    <Image src={img} alt={alt} margin="0 auto" maxH="100%" />
                  </Box>
                  <Text
                    textAlign="center"
                    fontSize={{ base: "sm", xl: "md" }}
                    fontFamily="GTMaru!important"
                    height="20%"
                  >
                    {item.label}
                  </Text>
                </Box>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <SwipperButton action="next" isPublicHome hide={!isDesktop} />
      </HStack>

      <HStack justifyContent="center" spacing={10} pt={3}>
        <SwipperButton action="prev" isPublicHome hide={isDesktop} />
        <SwipperButton action="next" isPublicHome hide={isDesktop} />
      </HStack>
    </Container>
  )
}
export default Carousel
