import { GetServerSidePropsContext } from "next"
import PublicHome from "../src/components/PublicHome"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import ConnectedHome from "@/components/ConnectedHome"
import { unstable_getServerSession } from "next-auth"
import { authOptions } from "./api/auth/[...nextauth]"
import client from "@/api/client"
import { LandingPageListResponseDataItem } from "@/typings/api"
import { captureException } from "@sentry/nextjs"
import { serializeSession } from "@/utils/session"

export default function Home({
  isAuthenticated,
  landingData,
}: {
  isAuthenticated: boolean
  landingData: LandingPageListResponseDataItem
}) {
  if (isAuthenticated) {
    return <ConnectedHome />
  }

  return <PublicHome landingData={landingData} />
}

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  let landingData = null
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  )

  if (!session) {
    try {
      const response = await client.landingPage.getLandingPage({
        // @ts-ignore
        "populate[presentation_1][populate][1]": "image",
        "populate[presentation_2][populate][1]": "image",
        "populate[presentation_3][populate][1]": "image",
        "populate[carousel][populate][1]": "image",
      })

      landingData = response.data.data
    } catch (e) {
    // eslint-disable-next-line
      console.error(e);
      captureException(e)
    }
  } else {
    serializeSession(session)
  }

  return {
    props: {
      ...(await serverSideTranslations(context.locale!)),
      isAuthenticated: !!session?.userId,
      session,
      landingData,
    },
  }
}
